import React, {useContext, useEffect, useState} from 'react';
import Layout from '../../components/layout';
import Section from '../../components/section';
import {getPackages, getUser} from '../../requests';
import {UserContext} from '../../context/user-context';
import ProductCard from '../../components/pricing/productCard';
import Switch from '../../components/switch';

import pricingIcon from '../../img/payments/pricing-hint.png';
import {closeIcon} from '../../img/svg-icons';

const Pricing = () => {
  const [plans, setPlans] = useState({
    ltd: null as any,
    monthly: null as any,
    annual: null as any,
  });
  const [annual, setAnnual] = useState(true);
  const {user, setUser} = useContext(UserContext);
  const [showUpgradeContact, setShowUpgradeContact] = useState(true);
  const [allDisabled, setAllDisabled] = useState(false);

  const setPlansData = async () => {
    const plansLocal = await getPackages();
    const userPlan = user?.package_data?.name;
    userPlan === 'PRO_ANNUAL' ? setAllDisabled(true) : setAllDisabled(false);
    const monthlyPlans = plansLocal
      .filter((item: any) => item.name !== 'free' && item.name?.includes('MONTH') && item.product_type === 'stripe')
      .map((item: any) => {
        return {
          ...item,
          paypal_id: plansLocal.find((sub: any) => sub.product_type === 'paypal' && sub.name === item.name)?.id,
          disabled: (userPlan?.includes('STARTER') && item.name.includes('STARTER')) || userPlan?.includes('PRO'),
          limit: item.name.includes('STARTER') ? '25,000' : '100,000',
        };
      })
      .sort((a: any, b: any) => {
        if (a.price < b.price) {
          return -1;
        } else {
          return 1;
        }
      });
    const annualPlans = plansLocal
      .filter((item: any) => item.name !== 'free' && item.name?.includes('ANNUAL') && item.product_type === 'stripe' && !item.name?.includes('OLD'))
      .map((item: any) => {
        return {
          ...item,
          paypal_id: plansLocal.find((sub: any) => sub.product_type === 'paypal' && sub.name === item.name)?.id,
          realPrice: item.name === 'PRO_ANNUAL' ? 588 : 240,
          realPriceMonth: item.name === 'PRO_ANNUAL' ? 49 : 20,
          disabled: (userPlan === 'STARTER_ANNUAL' && item.name === 'STARTER_ANNUAL') || userPlan === 'PRO_ANNUAL',
          limit: item.name.includes('STARTER') ? '25,000' : '100,000',
        };
      })
      .sort((a: any, b: any) => {
        if (a.price < b.price) {
          return -1;
        } else {
          return 1;
        }
      });
    plansLocal &&
      setPlans({
        ltd: null as any,
        monthly: monthlyPlans,
        annual: annualPlans,
      });
  };

  useEffect(() => {
    setPlansData();
  }, [user]);

  const refetchUser = async () => {
    const userInfo = await getUser();
    userInfo && setUser(userInfo);
  };

  const handleCloseModal = () => setShowUpgradeContact(false);

  useEffect(() => {
    setTimeout(() => {
      const path = global?.window?.location?.href;
      if (path && path?.includes('#pricing')) {
        document?.body?.scrollHeight && global?.window?.scrollTo(0, document?.body?.scrollHeight);
      }
    }, 1000);
  }, []);

  return (
    <Layout
      bg
      title={'Pricing Page | Search Scraper'}
      metaName={'description'}
      metaContent={''}
    >
      <Section lightGrey>
        <div>
          <h2 className="mb-2 text-center" style={{color: '#050038'}} id='pricing'>
            Search Scraper Pricing Plans
          </h2>

          <p className="text-center section-text lg">
            Take advantage of our <span style={{color: '#7C3AED'}}> SPECIAL LAUNCH OFFER </span> and save{' '}
            <span style={{color: '#7C3AED'}}>50% OFF</span> our plans for a limited time only.
          </p>
          {/* <Counter date={getPromoDate()} title="DEAL ENDS IN..." /> */}
        </div>

        <div className="pricing-wrapper">
          <div className="d-inline-block position-relative wrap-el w-100">
            <Switch
              text1="Monthly"
              text2="Annually"
              active={!annual}
              onChange={(val: string) => setAnnual(val === 'Annually')}
            />
          </div>
          <div className="d-block d-lg-flex products-wrapper justify-content-center">
            {allDisabled && showUpgradeContact && (
              <div className="text-center all-disabled">
                <span onClick={handleCloseModal}>{closeIcon}</span>
                <img src={pricingIcon} alt="icon" />
                <div>Need to upgrade your plan? </div>
                <div className="support">
                  Please contact{' '}
                  <a
                    href="mailto:support@searchscraper.io?subject=Search Scraper"
                    aria-label={'mail'}
                    target="_blank"
                    rel="noopener"
                  >
                    support@searchscraper.io
                  </a>
                  {'.'}
                </div>
              </div>
            )}

            {(annual ? plans.annual : plans.monthly)?.map((item: any, index: number) => (
              <ProductCard
                id={item.id}
                name={item.name}
                paypal_id={item.ltd ? item.ltd.paypal_id : item.paypal_id}
                color={item.color}
                stack={true}
                count={1}
                key={item.name}
                price={item.price}
                ltdPrice={item.ltd?.amount}
                popular={false}
                expariationDate={true}
                // pendingPayment={pendingPayment}
                user={!!(user && user.id)}
                verified={true}
                ltdUser={false}
                validUser={true}
                disabled={item.ltd ? item.ltd.disabled : item.disabled}
                limit={item.limit}
                refetchUser={refetchUser}
                realPriceMonth={item.realPriceMonth}
                realPriceAnnual={item.realPrice}
                annual={annual}
              />
            ))}
          </div>
          {/* <div className="text-center mt-4" style={{color: '#516077', fontSize: '14px', fontWeight: 500}}>
            All purchases are covered by our 30 day no questions asked refund policy.
          </div> */}
        </div>
      </Section>
    </Layout>
  );
};

export default Pricing;
